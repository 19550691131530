<template>
  <div class="mb-4 pb-4 z-indez-901">
    <GmapAutocomplete
      class="w-100 z-index-901"
      @place_changed="handlePlaceChanged"
      placeholder="Inserisci il luogo di partenza"
    />
  </div>
</template>

<script>
  export default {
    name: "CustomAutoCompleteGmap",
    methods: {
      handlePlaceChanged(event) {
        this.$emit("gmap-autocomplete::place-changed", event);
      },
    },
  };
</script>

<style scoped>
  .zindex-901 {
    z-index: 901 !important;
  }
</style>
