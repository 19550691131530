<template>
	<div class="content-wrapper">
		<div class="d-flex justify-content-between">
			<div>
				<h1 style="margin: 0">Ordini di servizio</h1>
				<h3 style="margin-top: 0">
					{{ dossier.codice ? dossier.codice : "" }} Dossier
					{{ dossier.titolo ? dossier.titolo : "" }} -
					{{ dossier.Company ? dossier.Company : "" }}
				</h3>
			</div>
			<div style="font-size: 1.5rem">
				<b-badge variant="warning">
					<b-icon-star-fill class="mr-2"></b-icon-star-fill> Plugin
				</b-badge>
			</div>
		</div>
		<b-card class="mb-4">
			<b-row class="my-1 justify-content-between">
				<div class="w-100 d-flex justify-content-between align-items-center">
					<b-button @click="$router.go(-1)"
						><b-icon icon="chevron-left" aria-hidden="true"></b-icon> Torna ai
						Dossier</b-button
					>

					<div class="d-flex justify-content-end align-items-center">
						<b-button
							size="sm"
							class="mb-2 mr-2"
							@click="$router.push('/axerta/timeline')"
							>Vai a calendario</b-button
						>
						<b-button
							size="sm"
							class="mb-2"
							v-b-toggle.sidebar-ordine-di-servizio
						>
							<b-icon icon="plus" aria-hidden="true"></b-icon> Nuovo Ordine di
							servizio
						</b-button>
					</div>
				</div>
			</b-row>
		</b-card>
		<b-overlay :show="items == null" rounded="sm" class="overlay-placeholder">
			<b-card>
				<b-table
					hover
					:items="formattedItems"
					:fields="fields"
					:current-page="currentPage"
					:per-page="perPage"
					:filter="filter"
				>
					<template #cell(luogo)="row">
						<span v-if="row.item.luogo.includes('goo.gl/maps')">
							<a :href="row.item.luogo" target="blank">Apri sulla mappa</a>
						</span>
						<span v-else>{{ row.item.luogo }}</span>
					</template>
					<template #cell(dataOra_inizio)="row">
						{{ new Date(row.item.dataOra_inizio).toLocaleDateString("it") }}
					</template>
					<template #cell(confermato)="row">
						<span style="font-size: 1.5rem">
							<b-icon-check
								v-if="row.item.confermato"
								class="ml-4"
							></b-icon-check>
							<b-icon-x v-else class="ml-4"></b-icon-x>
						</span>
					</template>
					<template #cell(elementi)="row">
						{{ row.item.elementi.length }}
					</template>
					<template #cell(azioni)="row">
						<b-dropdown
							size="sm"
							variant="link"
							toggle-class="text-decoration-none"
							no-caret
						>
							<template #button-content>
								<b-icon-gear-fill></b-icon-gear-fill>
							</template>

							<b-dropdown-item
								href="#"
								v-b-toggle.sidebar-ordine-di-servizio
								@click="
									currentItem = row.item;
									tmpItem = JSON.parse(JSON.stringify(row.item));
								"
								>Modifica</b-dropdown-item
							>
							<b-dropdown-item @click="duplica(row.item)" href="#"
								>Duplica</b-dropdown-item
							>
							<b-dropdown-item
								href="#"
								@click="
									tmpItem = row.item;
									showDeleteModal = true;
								"
								>Elimina</b-dropdown-item
							>
						</b-dropdown>
					</template>
				</b-table>
				<b-pagination
					v-model="currentPage"
					:total-rows="totalRows"
					:per-page="perPage"
					align="fill"
					size="sm"
					class="mt-4 mb-1"
				></b-pagination>
			</b-card>
		</b-overlay>

		<b-sidebar
			class="right-sidebar"
			id="sidebar-ordine-di-servizio"
			right
			shadow
			lazy
			@hidden="resetData"
			@shown="showSidebar"
			:visible="sidebarOrdineVisible"
			style="z-index:901 !important"
		>
			<div class="content-wrapper my-3 mx-1">
				<div
					v-if="tmpItem && tmpItem.id"
					class="d-flex justify-content-between"
				>
					<h4>
						{{ tmpItem.titolo || tmpItem.codice }}
					</h4>
					<div style="color: gray" class="d-flex flex-column">
						<span> Dossier: {{ dossier.codice }} </span>
						<span> {{ dossier.titolo }} - {{ dossier.Company }} </span>
					</div>
				</div>
				<h4 v-else>Nuovo ordine di servizio</h4>

				<b-row class="mb-4">
					<b-col>
						<b-form-checkbox v-model="tmpItem.confermato">
							Confermato
						</b-form-checkbox>
					</b-col>
				</b-row>

				<b-row class="mb-2">
					<b-col>
						<b-form-group label="Titolo">
							<b-form-input v-model="tmpItem.titolo" />
						</b-form-group>
					</b-col>
				</b-row>

				<b-row class="mb-4">
					<b-col>
						<label class="mr-2">CIE Assegnato</label>
						<b-form-select
							v-model="tmpItem.idCie"
							:options="CieOptions"
							@change="selectCie(cie)"
						>
						</b-form-select>
					</b-col>
				</b-row>

				<b-row>
					<b-col>
						<!-- <b-form-input v-model="tmpItem.luogo" /> -->
						<label>Luogo</label>
						<CustomGmapAutocomplete
							@gmap-autocomplete::place-changed="handlePlaceChanged"
						></CustomGmapAutocomplete>
					</b-col>
					<b-col>
						<b-form-group label="Link Maps">
							<b-form-input v-model="tmpItem.linkMaps" />
						</b-form-group>
					</b-col>
				</b-row>

				<b-row>
					<b-col>
						<b-form-group label="Data Ora suggerita">
							<b-row>
								<b-col>
									<b-form-datepicker
										v-model="tmpItem.dataOra_inizio"
										:min="new Date(dossier.dataInizio)"
										:max="new Date(dossier.dataFine)"
										locale="it"
									></b-form-datepicker>
								</b-col>

								<b-col>
									<vue-timepicker
										class="custom-timepicker"
										advanced-keyboard
										manual-input
										v-model="tmpItem.ora_suggerita"
									></vue-timepicker>
								</b-col>
							</b-row>
						</b-form-group>
					</b-col>
				</b-row>

				<b-row>
					<b-col>
						<b-form-group label="Durata Stimata (giorni)">
							<b-form-input type="number" v-model="tmpItem.durataStimata" />
						</b-form-group>
					</b-col>
				</b-row>

				<b-row v-if="tmpItem.elementi">
					<b-col>
						<b-form-group label="Elementi Dossier a supporto">
							<TagsInput
								v-model="elementiSelezionati"
								:tagsList="dossier.elementi"
							/>
						</b-form-group>
					</b-col>
				</b-row>

				<b-row>
					<b-col>
						<b-form-group label="Descrizione Ordine di Servizio">
							<VueEditor class="editor" v-model="tmpItem.annotazioni" />
							<!-- <b-form-textarea
                v-model="tmpItem.annotazioni"
                rows="3"
                max-rows="6"
              ></b-form-textarea> -->
						</b-form-group>
					</b-col>
				</b-row>

				<b-row>
					<b-col>
						<b-form-group label="Numero CIE assegnati">
							<b-form-input type="number" v-model="tmpItem.numeroCie" />
						</b-form-group>
					</b-col>
				</b-row>

				<div class="mt-5 mb-5 ">
					<!-- <b-button v-if="tmpItem.confermato" disabled squared variant="outline-primary" size="lg" class="big-button">
            <b-icon-check></b-icon-check> Confermato
          </b-button> -->
					<b-button
						squared
						:disabled="loading"
						variant="primary"
						size="lg"
						class="big-button"
						@click="saveItem"
					>
						{{ !loading ? "Salva" : "Loading..." }}</b-button
					>
				</div>
			</div>
		</b-sidebar>
		<b-modal
			v-model="showDeleteModal"
			title="Attenzione!"
			ok-title="Conferma"
			cancel-title="Annulla"
			@hidden="resetData"
			@ok="deleteItem(tmpItem.id)"
		>
			Sei sicuro di voler eliminare l'ordine di servizio
			{{ tmpItem.titolo || tmpItem.id }}?</b-modal
		>
	</div>
</template>

<script>
	import AxiosService from "../../axiosServices/AxiosService";
	import CustomGmapAutocomplete from "../../components/CustomGmapAutocomplete";
	import { VueEditor } from "vue2-editor";
	export default {
		components: {
			VueEditor,
			CustomGmapAutocomplete,
		},
		created() {},
		mounted() {
			this.idDossier = this.$route.params.idDossier;
			this.getDossierData();
			this.getData();
			this.getUsers();
		},
		data() {
			return {
				elementiSelezionati: [],
				currentPage: 1,
				perPage: 10,
				filter: "",
				idDossier: null,
				currentItem: null,
				loading: false,
				tmpItem: {
					id: null,
					titolo: "",
					annotazioni: "",
					codice: "",
					confermato: "",
					dataOra_inizio: "",
					elementi: [],
					idCie: "",
					idDossier: "",
					luogo: "",
					linkMaps: "",
					numeroCie: 1,
				},
				fields: [
					// { key: "codice", label: "Codice" },
					// { key: "id", label: "ID" },
					{ key: "titolo", label: "Titolo" },
					{ key: "luogo", label: "Luogo" },
					{ key: "Cie", label: "CIE" },
					{ key: "dataOra_inizio", label: "Inizio" },
					// { key: 'idCie', label: 'idCie' },
					// { key: 'idDossier', label: 'idDossier' },
					{ key: "numeroCie", label: "N. CIE" },
					{ key: "rilevazioni", label: "N. Rilevazioni" },
					{ key: "durataStimata", label: "Durata Stimata" },
					{ key: "confermato", label: "Confermato" },
					{ key: "azioni", label: "Azioni" },
				],
				items: [],
				sidebarOrdineVisible: false,
				showDeleteModal: false,
				CieOptions: [],
				dossier: null,
			};
		},
		methods: {
			handlePlaceChanged(e) {
				console.log(e);
				this.tmpItem.luogo = e.formatted_address;
				this.tmpItem.linkMaps = e.url;
			},
			showSidebar() {
				this.tmpItem.elementi.forEach((element) => {
					this.elementiSelezionati.push(element.id);
				});
				this.tmpItem.ora_suggerita = this.getTimeFromString(
					this.tmpItem.dataOra_inizio
				);
				this.sidebarOrdineVisible = true;
			},
			getDossierData() {
				const service = new AxiosService(
					"DossierManager/GetDossier/" + this.idDossier
				);
				service.read().then((res) => {
					console.log(res);
					res.elementi.forEach((elemento) => {
						elemento.text = elemento.tag;
						elemento.value = elemento.id;
					});
					this.dossier = res;
				});
			},
			getData() {
				this.loading = true;
				this.items.splice(0);
				const service = new AxiosService(
					"DossierManager/GetOrdiniDiServizio/" + this.idDossier
				);
				service
					.read()
					.then((res) => {
						this.items = res;
					})
					.finally(() => {
						this.loading = false;
					});
			},
			resetData() {
				this.tmpItem = {
					id: null,
					titolo: "",
					annotazioni: "",
					codice: false,
					confermato: "",
					dataOra_inizio: "",
					elementi: [],
					idCie: "",
					idDossier: "",
					luogo: "",
					numeroCie: 1,
				};
				this.elementiSelezionati.splice(0);
			},
			getTimeFromString(timeString) {
				const date = new Date(timeString);
				const hours = String(date.getHours()).padStart(2, "0");
				const minutes = String(date.getMinutes()).padStart(2, "0");

				return {
					HH: hours,
					mm: minutes,
				};
			},
			saveItem() {
				this.loading = true;

				let nuoviSelezionati = [];

				this.tmpItem.dataOra_inizio =
					this.tmpItem.dataOra_inizio +
					"T" +
					`${this.tmpItem.ora_suggerita.HH}:${this.tmpItem.ora_suggerita.mm}:00`;

				this.elementiSelezionati.forEach((selezionato) => {
					this.dossier.elementi.forEach((elemento) => {
						if (elemento.id == selezionato) {
							nuoviSelezionati.push({
								id: elemento.id,
								tag: elemento.tag,
								descrizione: elemento.descrizione,
								allegati: elemento.allegati,
								delete: false,
							});
						}
					});
					this.tmpItem.elementi = nuoviSelezionati;
				});

				if (this.tmpItem.id) {
					console.log("PUT");
					const service = new AxiosService(
						"DossierManager/EditOrdineDiServizio"
					);

					this.tmpItem.elementi;
					service
						.update(this.tmpItem)
						.then((res) => console.log(res))
						.finally(() => {
							this.sidebarOrdineVisible = false;
							this.getData();
							this.loading = false;
						});
				} else {
					console.log("POST");
					const service = new AxiosService(
						"DossierManager/AddOrdineDiServizio/" + this.$route.params.idDossier
					);
					service
						.create(this.tmpItem)
						.then((res) => console.log(res))
						.finally(() => {
							this.sidebarOrdineVisible = false;
							this.getData();
							this.loading = false;
						});
				}
			},

			duplica(item) {
				this.loading = true;
				const service = new AxiosService(
					"DossierManager/DuplicateOrdineDiServizio/" + item.id
				);
				service
					.create(item)
					.then((res) => console.log(res))
					.finally(() => {
						this.sidebarOrdineVisible = false;
						this.getData();
						this.loading = false;
					});
			},
			deleteItem(id) {
				this.loading = true;
				const service = new AxiosService(
					"DossierManager/DeleteOrdineDiServizio"
				);
				service
					.delete(id)
					.then((res) => console.log(res))
					.finally(() => {
						this.showDeleteModal = false;
						this.getData();
						this.loading = false;
					});
			},
			getUsers() {
				const service = new AxiosService("Option/User");
				service.read().then((res) => {
					this.CieOptions = res;
				});
			},
			selectCie(item) {
				this.tmpItem.Cie = item.text;
				this.tmpItem.idCie = item.value;
			},
			getVariant(confermato) {
				switch (confermato) {
					case true:
						return "success";
					case false:
						return "";
					default:
						return "";
				}
			},
		},
		computed: {
			totalRows() {
				return this.items ? this.items.length : 0;
			},
			formattedItems() {
				if (!this.items) return [];
				return this.items.map((item) => {
					item._rowVariant = this.getVariant(item.confermato);
					return item;
				});
			},
			// ...mapGetters(["languages", "default_lang", "config"]),
			//     ...mapGetters(["default_lang"]),
			//     ...mapGetters(["config"]),
		},
	};
</script>
<style scoped lang="scss">
	.editor {
		background-color: #fff;
		.ql-editor {
			min-height: 600px;
		}
		.ql-snow {
			&.ql-toolbar,
			&.ql-container {
				border: 1px solid #b6b6b6;
			}
		}
	}
</style>
